.cursor-pointer {
  cursor: pointer;
}

.rounded-border {
  border-radius: 1rem;
}

.navIcons {
  background: linear-gradient(135deg, #6c91ac, #0e2038);
  color: white;
  padding: 0.6rem 0.7rem;
  border-radius: 1rem;
}
.navIcons.active,
.navIcons:hover {
  box-shadow: 0 0 0.5rem rgba(0, 123, 255, 1);
}
.navIcons[disabled] {
  background: #f0f0f0;
  color: #c0c0c0;
  cursor: not-allowed;
}

.navIcons[disabled]:hover {
  box-shadow: none;
}

.blur {
  backdrop-filter: blur(1.5rem);
  background-color: transparent;
}

.label {
  margin: 1rem 0 0.5rem 0.1rem;
}

.background {
  background-image: url("/public/assets/images/hero.png");
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  min-height: 100vh;
  /* background fixed */
}

.customFont {
  font-family: "Bell MT";
  font-weight: "200";
}
