.backdrop {
  position: fixed;
  z-index: 3000;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-out;
}

.backdrop.active {
  opacity: 1;
  pointer-events: all;
}

.nova-law-modal {
  z-index: 3001;
  position: fixed;
  top: 0%;
  left: 50%;
  width: 25rem;
  max-width: 95%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-out;
  text-align: center;
  min-width: 20rem;
}
.nova-law-modal.active {
  top: 50%;
  opacity: 1;
  pointer-events: all;
}

.nova-law-modal .close {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  font-size: 1.5rem;
}

:root {
  --sideBarWidth: 4rem;
  --sideBarWidthActive: 12rem;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.5); */
  z-index: 999;
  opacity: 0;
  pointer-events: none;
}
.backdrop.active {
  opacity: 0;
  pointer-events: none;
}

.memberSidebar.active,
.memberSidebar:hover {
  width: var(--sideBarWidthActive);
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2); */
  /* background-color: white; */
}

.memberSidebar {
  width: var(--sideBarWidth);
  box-shadow: none;
  /* border: red 1px solid; */
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease;
}
.memberDashboard {
  transition: all 0.3s ease;
  padding: 4rem 0rem 4rem 4rem;
}
.memberDashboard.background {
  background-image: url("/public/assets/images/hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.background {
  background-image: url("/public/assets/images/hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.memberDashboard.active {
  padding-left: var(--sideBarWidthActive);
}

@media (max-width: 994px) {
  .memberSidebar {
    position: fixed;
    width: 0;
    max-width: 100%;
  }

  .memberDashboard {
    padding-left: 0;
    padding-right: 0;
  }
  .memberDashboard.active {
    padding-left: 0;
  }
  .backdrop.active {
    opacity: 1;
    pointer-events: all;
  }
}

.memberNavbar {
  /* background-color: #fff; */
  background-color: white;
  height: 4rem;
  position: fixed;
  width: 100%;
  z-index: 1001;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.memberLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.membersLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh);
}

.memberNav {
  padding-top: var(--sideBarWidth);
}

.memberNav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.memberNav ul li {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.memberNav ul li > i,
.navIcons {
  background: linear-gradient(135deg, #6c91ac, #0e2038);
  color: white;
  padding: 0.6rem 0.7rem;
  border-radius: 1rem;
}

.memberNav ul li > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  visibility: hidden;
  width: 0;
  margin-left: 0.5rem;
  font-size: 1.1rem;
}

.memberNav ul li.active {
  color: #6c91ac;
}
.memberNav ul li:hover {
  background-color: #f5f5f5;
}

.memberSidebar.active span,
.memberSidebar:hover span {
  visibility: visible;
  width: auto;
}

.ProfileSidebar {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 1002;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  overflow-y: scroll;
}

.ProfileSidebar.active {
  right: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pulsingLoader {
  content: " ";
  background: #ccc;
  position: relative;
  top: 0;
  left: 0;
  width: 40rem;
  max-width: 100%;
  aspect-ratio: 2/1;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

.card-loader {
  width: 100%; /* Adjusted to take the full width */
  height: 20rem; /* Keep height fixed or adjust as needed */
  display: block;
  position: relative;
  /* background: #fff; */
  box-sizing: border-box;
}

.card-loader::after {
  border-radius: 0.3rem;

  content: "";
  width: calc(100%);
  height: calc(100%);
  /* top: 15px;
  left: 15px; */
  position: absolute;
  background-image: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.5) 50%,
      transparent 100%
    ),
    linear-gradient(#ddd 100%, transparent 0);
  background-repeat: no-repeat;
  /* Adjusted background-size to be more responsive */
  background-size: 15% 100%, 100% 100%, 80% 16px, 80% 16px;
  /* Adjust background-position to start from dynamic points for full width */
  background-position: 0 0, center 0px, center 125px, center 155px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
}

@keyframes animloader {
  /* Adjust animation to ensure full-width movement */
  to {
    background-position: 100% 0, center 0, center 125px, center 155px;
  }
}

@font-face {
  font-family: "Bell MT";
  src: url("/public/assets/font/Bell\ MT.ttf") format("truetype"); /* Adjust the path and format accordingly */
  font-weight: normal;
  font-style: normal;
}
